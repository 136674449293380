
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {defineComponent} from "vue";
import { ConvertDate } from '@/components/converters/date';

export default defineComponent({
  name: 'PricesTableRow',
  components: {
    BaseIcon,
  },
  props: {
    currentItems: {
      type: Object,
      required: false,
      default: () => null
    },
    row: {
      type: Array,
      required: true,
      default: () => []
    },
    isHeader: {
      type: Boolean,
      default: () => false
    },
    descHidden: {
      type: Boolean,
      default: () => false
    },
    sort: {
      type: String,
      default: () => ''
    },
    priceFocus: {
      type: String,
      default: () => 'all'
    },
    minPrice: {
      type: Number,
      default: () => 0
    },
    maxPrice: {
      type: Number,
      default: () => 0
    },
    articleId: {
      type: Number,
      default: () => 0
    },
    numberColumns: {
      type: Number,
      default: () => 0
    },
    prices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isPriceOutdated(): (brandName: string) => boolean {
      return (brandName) => {
        // Проходим по всем объектам в основном массиве
        for (const item of (this.prices[0] as any).prices) {
          const prices = item.prices;

          // Ищем прайсы для указанного бренда
          const brandPrices = prices.filter((price: any) => price.supplier === brandName);

          // Если есть прайсы для этого бренда, проверяем их на устаревание
          if (brandPrices.length > 0) {
            const isOutdated = brandPrices.some((price: any) => {
              const priceDate = new Date(price.datePrice);
              const currentDate = new Date();
              const diffTime = currentDate.getTime() - priceDate.getTime();
              const diffDays = diffTime / (1000 * 3600 * 24);
              return diffDays > 7;
            });

            // Если хотя бы один прайс устарел, возвращаем true
            return isOutdated
          }
        }

        // Если ни один прайс не устарел, возвращаем false
        return false;
      };
    },
    getCurrentDatePrice() {
      return (index: number) => {
        if (this.currentItems[index] !== '-') {
          return ConvertDate(this.currentItems[index])
        } else {
          return this.currentItems[index]
        }
      }
    },
    getHeaderName() {
      return (item: object) => item
    },
    getBodyName() {
      return (item: object) => item
    },
    itemIsMin() {
      return (item: number) => {
        return item <= this.minPrice
      }
    },
    itemIsMax() {
      return (item: number) => {
        return item >= this.maxPrice
      }
    }
  },
  methods: {
    setSort(): any {
      this.$emit('setSort')
    }
  },
})
